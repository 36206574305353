.mapContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  background: #f4f4f4 !important;

  font-family: if($font != "", $font !important, initial);

  &.with-header {
    top: $header-height;

    @include mobile {
      top: $header-height-mobile;
    }
  }
}

.leaflet-control-legend {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 4px;
  cursor: pointer !important;

  &:hover {
    background-color:#f4f4f4;
  }
}

.legend-panel {
  position:fixed;
  bottom: 50px;
  right: 55px;
  padding: 10px;
  z-index: 9998;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 200px;
  height: 200px;

  @include mobile {
    width: auto !important;
    top: 10px;
    left: 10px;
    right: 10px;
  }

  .legend-close {
    position: absolute;
    padding: 10px 10px 0px 10px;
    text-align: right;
    right: 0px;
    top: -5px;
  }

  .legend-img {
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

.infobox {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  margin: 0;

  border-radius: 5px;
  background: #ffffff;
  box-shadow: 2px 2px 10px $shadow-xs;

  font-family: $font, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  font-size: 1.166666em;

  &:before {
    content: '';
    z-index: 99999;
    width: 0px;
    position: fixed;
    left: -14px;
    height: 0px;
    top: 12px;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent $primary transparent transparent;
  }

  &.no-arrow {
    &:before {
      content: none;
    }
  }

  @include small-mobile {
    font-size: 1em;

    .infobox-content {
      min-width: 150px !important;

      .line {
        min-width: 35px !important;
        padding: 6px 5px !important;
      }
    }
  }

  .infobox-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;

    padding: 10px;

    color: $secondary;
    border-bottom: 1px solid $shadow-xs;
    background-color: $primary;

    font-weight: 600;

    .infobox-title-tools {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      &.with-pmr {
        flex: 1;
      }

      .is-pmr {
        background: url(/assets/images/pmr-infobox.svg) no-repeat center;
        margin-left: 10px;
        height: 20px;
        width: 20px;
      }

      .is-no-pmr {
        background: url(/assets/images/no-pmr.svg) no-repeat center;
        margin-left: 5px;
        height: 15px;
        width: 15px;
      }

      .is-tad {
        background: url(/assets/images/tad.svg) no-repeat center;
        margin-left: 10px;
        height: 20px;
        width: 20px;
      }
  
      .tool-route-calculation {
        min-width: 20px;
        max-width: 20px;
        margin: -6px 0 -6px 10px;
        margin-left: 10px;
  
        background-size: 75%;
        background-color: $secondary;
      }
    }
  }

  .infobox-services-station {
    display: flex;
    flex-direction: column;

    span {
      padding: 5px 0px 0px 10px;
    }

    .services-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 5px 0 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    img {
      height: 25px;
      padding: 0 5px 10px 5px;
    }
  }

  .infobox-content {
    min-width: 200px;
    padding: 10px;
    
    .connections-at-stop {
      @include font-small;
      font-weight: 600;
    }

    .infobox-lines {
      display: grid;

      padding: 0;

      grid-gap: 5px;
      grid-template-columns: repeat($grid-size, 1fr);

      &.line-with-direction {
        grid-template-columns: repeat(1, 1fr);
        
        .attribute-line {
          display: flex;
          align-items: center;
          cursor: pointer;
    
          .line {
            padding: 5px 5px;
            min-width: 50px;
            max-width: 50px;
    
            &.code {
              @include font-small;
            }
    
            &.mode {
              @include font-xx-small;
            }
          }
    
          .name {
            padding-left: 10px;
            text-align: left;
            font-size: 0.875em;
            white-space: nowrap;
          }
        }
      }

      &:not(:last-child) {
        padding-bottom: 5px;
      }

      .line {
        display: flex;
        position: relative;
        justify-content: center;

        //min-width: 40px;
        padding: 10px 7px;

        cursor: pointer;
        text-align: center;

        .tools-at-line {
          display: flex;
          flex-direction: column;
          position: absolute;
          right: 2px;
        }

        .tool-line {
          &:not(:first-child) {
            padding-top: 3px;
          }
        }

        .is-tad-at-line {
          background: url(/assets/images/tad.svg) no-repeat center;
          height: 15px;
          width: 15px;
        }

        &:hover {
          cursor: pointer;

          background: $hover;
        }

        img {
          height: calc(#{$line-image-height} - 5px);
        }
      }

      @include mobile {
        grid-template-columns: repeat($grid-size-mobile, 1fr);
      }

      @include small-mobile {
        grid-template-columns: repeat($grid-size-small-mobile, 1fr);
      }
    }

    .place {
      .info {
        margin-top: 5px;
      }
      
      .realtime-seats {
        background: #e0e0e0;
        border-radius: 5px;
        padding: 3px;
        align-self: flex-start;
        margin-bottom: 5px;

        img {
          width: 15px;
          padding-left: 3px;
        }
      }

      .bss {
        padding: 5px 0;
        display: flex;
      }
    }
  }
}

.popup-leaflet {
  .leaflet-popup-content-wrapper {
    background: transparent;
    box-shadow: none;

    .leaflet-popup-content {
      margin: 0;
      width: auto !important;
    }
  }

  .leaflet-popup-tip-container {
    display: none;
  }
}

.tooltip-leaflet-terminus {
  padding: 4px 5px !important;

  font-weight: 500;

  &:before {
    border: none !important;
  }
}

// Circle icon marker
.circle-icon-marker {
  span {
    position: absolute;
    top: -2px;
    left: -2px;

    display: block;

    width: 100%;
    height: 100%;

    border-radius: 50%;
    background-color: white;
  }
}

// Remove Leaflet Popup fade
/*.leaflet-fade-anim .leaflet-popup {
  transition: none !important;
}*/

.polyline-decorator {
  z-index: 9999 !important;

  padding: 2px 0;

  border: 1px solid $border-m;
  border-radius: 2px;
  background: white;
}

.polyline-decorator-fb {
  z-index: 9999 !important;

  padding: 2px 0;

  border-radius: 2px;
}

.polyline-decorator-color {
  width: auto !important;
  height: auto !important;
  margin-top: -18px !important;
  margin-left: -24px !important;
  z-index: 9999 !important;
  padding: 2px;

  background: white;
  border: 1px solid $border-m;
  border-radius: 2px;

  .decorator {
    padding: 2px 12px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.cluster {
  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 23px !important;
  height: 23px !important;

  color: $primary;
  border: solid 1.2px rgba($primary, 0.8);
  border-radius: 5px;
  background-color: white;
}
