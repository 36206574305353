// Desktop mixi
@mixin desktop {
  @media screen and (min-width: #{$break-mobile}) {
    @content;
  }
}

/// Mobile mixin
@mixin mobile {
  @media screen and (max-width: #{$break-mobile}) {
    @content;
  }
}

/// Mobile mixin
@mixin small-mobile {
  @media screen and (max-width: #{$break-small-small}) {
    @content;
  }
}

@mixin font-small {
  font-size: .875em;
}

@mixin font-x-small {
  font-size: .775em;
}

@mixin font-xx-small {
  font-size: .53em;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;
  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_')
  );
  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '-' + $weight + '.' + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-weight: $weight;
    font-style: $style;

    src: $src;
  }
}

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

/// Returns the luminance of `$color` as a float (between 0 and 1)
/// 1 is pure white, 0 is pure black
/// @param {Color} $color - Color
/// @return {Number}
/// @link http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef Reference
@function luminance($color) {
  $colors: (
    'red': red($color),
    'green': green($color),
    'blue': blue($color)
  );

  @each $name, $value in $colors {
    $adjusted: 0;
    $value: $value / 255;
    $colors: map-merge(
      $colors,
      (
        $name: $value
      )
    );

    @if $value < 0.03928 {
      $value: $value / 12.92;
    } @else {
      $value: ($value + 0.055) / 1.055;
      $value: pow($value, 2.4);
    }
  }

  @return (map-get($colors, 'red') * 0.2126) + (map-get($colors, 'green') * 0.7152) +
    (map-get($colors, 'blue') * 0.0722);
}
