$font: "Arial";

// Default colors
$primary: #A1006A;
$secondary: #fff;

$hover: #efefef;
$secondary-journey: #888;

$text: #58585a;
$error: #FF0000;
$warning: #EF662F;
$info: #43B77A;

// Borders
$border-white-xs: rgba(255, 255, 255, 0.2);
$border-xs: rgba(0, 0, 0, 0.08);
$border-m: rgba(0, 0, 0, 0.15);

// Shadows
$shadow-xxs: rgba(0, 0, 0, 0.02);
$shadow-xs: rgba(0, 0, 0, 0.15);
$shadow-m: rgba(0, 0, 0, 0.3);
$shadow-xl: rgba(0, 0, 0, 0.4);

// Breakpoints CSS
$break-mobile: 600px;
$break-small-small: 360px;

$grid-size: 1;
$grid-size-mobile: 1;
$grid-size-small-mobile: 1;

$line-image-height: 20px;
$line-with-border: true;

$override-styles: true;

$is-iframe: true;

$header-height: 0px;
$header-height-mobile: 0px;

$board-mobile-margin-top: 80px;
$board-mobile-translateY-offset: 160px;
$board-mobile-default-top: 220px;