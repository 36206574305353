.module {
  &.bikes {
    display: inherit;

    .tab-panel {
      padding: 15px;

      @include font-small;
    }

    .bike {
      display: flex;
      height: inherit;
      cursor: pointer;
      padding: 5px;

      .bike-content {
        margin-left: 10px;

        .bike-available {
          display: flex;
          align-items: center;
          padding: 10px 0 5px;

          .loading {
            padding: 0;
            align-items: initial;

            img {
              width: 20px;
              padding: 0;
            }
          }

          .available, .capacity {
            display: flex;
            align-items: center;

            img {
              width: 25px;
            }
          }

          .capacity {
            margin-left: 10px;

            img {
              width: 12px;
            }
          }
        }
      }
    }

    .legend {
      display: flex;
      align-items: center;
      padding: 5px 0;

      .image {
        display: flex;
      }
      
      .name {
        margin-left: 15px;
      }
    }
  }
}
