@mixin override {
  // Override defaults styles
  .mode-line {
    .name {
      white-space: nowrap;
    }
  }

  .group .place {
    .informations-display {
      div {
        flex: unset;
        margin: unset;
        justify-content: unset;
      }

      flex-direction: column;
      align-items: flex-start;

      .services-lines {
        flex-direction: column;
        padding-top: 10px;

        .line-with-direction {
          align-items: center;
          padding-top: 5px;

          .line {
            padding: 5px 7px;
            margin-right: 10px;
            white-space: nowrap;

            &:hover {
              background: none;
            }
          }
        }
      }

      .internet {
        padding-top: 10px;

        .picto {
          background: url(/assets/images/url.svg) no-repeat center;
          margin: 0 5px 0 5px;
          height: 15px;
          width: 15px;
        }
      }

      .info-service {
        padding-top: 10px;

        .picto {
          background: url(/assets/images/info.svg) no-repeat center;
          margin: 0 5px 0 5px;
          height: 15px;
          width: 15px;
        }
      }

      .localisation {
        padding-top: 10px;

        .picto {
          background: url(/assets/images/localisation.svg) no-repeat center;
          margin: 0 5px 0 5px;
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .legend-panel {
    width: 280px;
    height: 220px;
  }

  .active-line .line-header .timetableOptions {
    left: 0;

    .timetableOptionsItem {
      text-align: left;
    }
  }

  .infobox-content {
    max-height: 190px;
    overflow-y: scroll;
  }
}
