.board {
  background: #f1f5f5;
  border-radius: 10px;
  box-shadow: 0 6px 35px 0 $shadow-xs;
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 400px;
  z-index: 9999;

  a {
    color: $primary;
    text-decoration: none;

    &.login {
      color: white;
    }
  }

  &.no-module {
    overflow-y: auto;
  }

  &.large {
    width: 440px;
  }

  &.small {
    width: 320px;

    @include font-small;

    .menu-item-description {
      padding: 5px 10px 0 0 !important;
    }

    .group-mode-logo {
      width: 35px;
    }

    .with-direction {
      width: 40px;
    }

    .group {
      padding: 15px 20px 5px 10px;
    }
  }

  .board-header {
    background: #f1f5f5;
    padding: 15px;
    border-radius: 10px 10px 0 0;
    display: flex;
    height: 70px;

    .back {
      align-items: center;
      border-top-left-radius: 15px;

      background: white;
      border-radius: 5px 0 0 5px;
      outline: none;
      border: 0;
      border-right: 1px solid #e3e4e4;

      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      padding: 0 15px;
      vertical-align: middle;
      height: 70px;
      width: 70px;

      img {
        height: 28px;
      }
    }

    .board-title {
      align-items: center;
      display: inline-flex;
      padding-left: 20px;
      color: $text;
      font-weight: 500;
      background: white;
      flex: 1;
      border-radius: 0 5px 5px 0;
      margin: 0;
      font-size: 1em;
      white-space: pre;

      &.with-town {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: initial;

        .board-title-town {
          @include font-small;
          color: #888;
        }
      }

      &.board-title-lock {
        border-radius: 5px;
      }

      .images {
        height: 32px;
      }
    }
  }

  // .scrolling {
  //   @include mobile {
  //     overflow-y: scroll;
  //     padding-bottom: 20px;
  //   }
  // }

  .content,
  .sub-content {
    background: #f1f5f5;
    border-radius: 10px;
    flex: 1;
    overflow-y: auto;

    // -ms-overflow-style: none;
    // scrollbar-width: none;

    &::-webkit-scrollbar {
      //width: 0 !important;
    }

    &.no-scroll {
      overflow: hidden;
    }

    &.main {
      background: transparent;
      margin: 15px;
      box-shadow: 0 5px 22px 0 rgba(112, 113, 115, 0.25);
      border-radius: 5px;
      overflow-y: initial;
    }

    .fallback {
      padding: 10px 20px 20px;
    }

    .menu-item {
      background: white;
      align-items: center;
      border-bottom: 1px solid #e3e4e4;
      color: #333333;
      display: flex;
      flex: 1;
      padding: 20px 10px;
      text-decoration: none;

      &:hover {
        background: $hover;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .images {
        height: 25px;
      }

      .menu-item-content {
        flex: 1;
        padding-left: 10px;

        .menu-item-description {
          padding: 5px 20px 0 0;
          font-weight: 300;
        }
      }

      .menu-item-arrow {
        margin: 0 10px;
      }
    }

    .menu-item-page {
      background: white;
      display: flex;
      flex: 1;
      font-size: 0.85em;
      justify-content: flex-end;
      padding: 10px 20px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background: $hover;
      }

      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .menu-title {
      color: #58585a;
      font-size: 1em;
      font-weight: 500;
      text-transform: none;
    }

    .sub-module {
      display: flex;
      justify-content: space-around;
      text-align: center;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .menu-item {
        justify-content: flex-start;
        flex-direction: column;
        font-size: 0.875em;
        padding: 10px;

        &:first-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-bottom-right-radius: 5px;
        }

        &:not(:last-child) {
          border-right: 1px solid #e3e4e4;
        }
      }

      .images {
        width: 25px;
      }
    }

    .react-tabs {
      margin: 0 15px 15px;
      background: white;
      border-radius: 5px;

      .menu-title {
        color: $text;
        text-transform: none;
      }

      .tab-list {
        .tab {
          padding: 15px;
          text-transform: none;
        }
      }
    }

    &.page {
      padding: 15px;

      .title {
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 1em;
        margin-top: 2em;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .sub-content {
    margin: 0 15px 15px !important;

    &.main {
      .menu-item {
        .images {
          height: 35px !important;
        }

        &:first-child {
          border-radius: 5px;
        }
      }
    }
  }

  @include desktop {
    transform: none !important;
  }

  @include mobile {
    flex: 1;
    margin: 0;
    width: 100% !important;
    overflow-x: hidden;
    margin-top: calc(#{$header-height-mobile} + #{$board-mobile-margin-top});

    &.no-module {
      overflow-y: scroll;
      padding-bottom: 20px;
    }

    .board-header {
      height: 60px;
      padding: 0 10px 10px !important;

      .back {
        width: 60px;
        height: 60px;

        img {
          height: 28px;
        }
      }

      .board-title {
        padding-left: 15px;

        .images {
          height: 28px;
          width: initial;
        }
      }
    }

    .evier-metal {
      background: #aaa;
      width: 30px;
      min-height: 4px;
      margin: 8px auto;
      border-radius: 5px;
    }

    .content {
      flex: initial;
      border-radius: 0;

      &.main {
        margin: 0 10px !important;
      }

      .react-tabs {
        margin: 0 10px 10px;

        .tab {
          padding: 10px !important;
        }

        .group {
          padding: 10px 10px 0;
        }
      }

      .form {
        .prefs {
          margin: 10px 0 0;

          .route-calculation-modes {
            margin: 10px 2px 0;
            grid-gap: 10px;
          }
        }

        .buttons {
          flex-direction: column;
          align-items: initial;
          padding-bottom: 0;

          .collapse {
            width: initial !important;
            flex: 1;

            &.date {
              margin: 0 0 10px;
            }
          }
        }

        .inputs {
          .input {
            padding: 5px 10px;
          }
        }

        .autocomplete {
          left: 15px;
          right: 15px;
          margin-top: 35px;
          max-height: 250px;
          width: auto;

          &.offsetRoute {
            width: 80% !important;
          }
        }
      }

      .menu-item {
        padding: 15px;
      }

      .go-to-route {
        padding: 0;
        font-size: 0.85em;

        img {
          width: 20px;
        }
      }
    }

    .sub-content {
      flex: initial;

      &.main {
        margin: 10px !important;
      }
    }

    .elevation {
      margin: 0 10px 10px;

      .lines {
        grid-template-columns: repeat($grid-size-mobile, 1fr);
      }

      .group {
        padding: 10px;

        .group-name {
          margin-bottom: 0;
        }

        .group-offset-bottom {
          margin-bottom: 0;
          margin-top: 10px;
        }
      }
    }

    .form {
      padding: 0 10px 10px;

      .button {
        margin-top: 10px;
      }
    }

    .journeys {
      padding: 5px 10px 0;

      .journey {
        margin: 10px 0 !important;

        .journey-time {
          padding: 0 10px !important;
        }
      }

      .time {
        white-space: nowrap;
        padding: 6px !important;

        span {
          flex-direction: column;
          padding: 0 5px;

          span {
            padding: 0 !important;
          }
        }
      }

      .soft {
        .time {
          padding: 0 !important;
        }
      }

      .transport {
        margin-bottom: 0;

        .leave {
          padding: 5px 15px 5px 5px;
        }
      }
    }

    .roadmap {
      padding: 20px 10px 10px !important;

      .section-part-top,
      .section-part-bottom,
      .section-part-content {
        grid-template-columns: 45px 40px 1fr;

        .section-part-bull {
          left: 12px;
        }

        .section-part-details-stops-bull {
          left: -25px !important;
        }

        .root-icon {
          margin-left: 85px !important;
        }
      }
    }
  }

  @include small-mobile {
    .content,
    .sub-content {
      .menu-item {
        padding: 10px !important;
      }

      .board-header {
        height: 45px;

        .back {
          width: 45px;
          height: 45px;

          img {
            height: 20px;
          }
        }

        .board-title {
          @include font-small;
        }
      }

      .menu-title {
        @include font-small;
      }

      .form {
        display: block !important;
      }

      .group-name {
        .mode {
          font-size: 1em !important;
        }
      }

      .route-calculation-mode {
        font-size: 0.8em;

        img {
          height: 25px;
        }
      }

      .stop {
        .seeTimetable {
          font-size: 0.75em !important;
        }
      }

      .timetable-stop {
        font-size: 0.9em !important;
      }

      .timetable {
        font-size: 0.9em !important;
      }

      .lines {
        grid-template-columns: repeat($grid-size-small-mobile, 1fr);

        &.line-with-direction {
          grid-template-columns: repeat(1, 1fr);
          
          .attribute-line {
            display: flex;
            align-items: center;

            .line {
              padding: 5px 5px;
              width: 50px;

              &.code {
                @include font-small;
              }
      
              &.mode {
                @include font-xx-small;
              }
            }

            .name {
              padding-left: 10px;
              text-align: left;
              font-size: 0.875em;
            }
          }
        }
      }

      .journeys {
        .soft-items {
          .softInfos {
            font-size: 0.9em;
            padding-left: 2px !important;

            .time {
              font-size: 1em;
            }
          }
        }

        .transport {
          .transport-title {
            font-size: 1em;
          }

          .journey {
            font-size: 0.9em;
          }
        }

        .leave {
          padding: 4px 10px 4px 0 !important;
        }
      }

      .roadmap {
        padding: 20px 10px;
        font-size: 0.9em;
      }
    }

    .header {
      height: 50px;

      .back {
        img {
          height: 40px;
        }
      }
    }
  }
}

.thematic-board-text {
  padding: 15px;

  @include font-small;
}

.elevation {
  margin: 0 15px 15px;
  background: white;
  border-radius: 5px;
}

.scroll {
  overflow-y: auto !important;

  // -ms-overflow-style: none;
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   width: 0 !important;
  // }
}

.share {
  position: absolute;
  top: 57px;
  left: 400px;
  padding: 5px;
  background: url(/assets/images/share.svg) no-repeat center;
  height: 15px;
  width: 15px;
  cursor: pointer;
  z-index: 99999;

  @include mobile {
    top: 38px;
    left: initial;
    right: 25px;
  }

  @include small-mobile {
    top: 33px;
    right: 20px;
    padding: 3px;
  }

  &.with-header {
    top: 177px;

    @include mobile {
      top: 58px;
      left: initial;
      right: 25px;
    }

    @include small-mobile {
      top: 43px;
      left: initial;
      right: 25px;
      padding: 3px;
    }
  }
}

#login {
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-shadow: 0 0 10px $shadow-xs;
  opacity: 1;
}

#share {
  display: flex;
  align-items: center;
  padding: 8px;
  box-shadow: 0 0 10px $shadow-xs;
  opacity: 1;

  a,
  div {
    padding: 0 5px;
  }

  .pdf {
    img {
      width: 15px;
      padding: 3px 5px 0;
    }
  }

  .messenger {
    img {
      width: 25px;
      padding: 3px 0 0;
    }
  }

  .twitter {
    img {
      width: 20px;
      padding: 3px 0 0;
    }
  }

  .whatsapp {
    img {
      width: 25px;
      padding: 3px 0 0;
    }
  }

  @include mobile {
    .pdf {
      img {
        width: 20px;
        padding: 3px 5px 0;
      }
    }

    .twitter {
      img {
        width: 25px;
        padding: 3px 5px 0;
      }
    }

    .whatsapp {
      img {
        width: 30px;
        padding: 3px 0 0;
      }
    }
  }
}

::placeholder {
  color: #767676;
}

.menu-item-arrow {
  background: url(/assets/images/menu-item-arrow.svg) no-repeat center;
  height: 20px;
  margin-left: 10px;
  width: 15px;
}

.images {
  padding: 5px;
  width: 40px;
}

.go-to-route {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: -20px;
  padding: 20px;
  font-style: italic;

  &:hover {
    background: $hover;
  }

  img {
    padding-right: 15px;
    width: 25px;
  }

  &.around {
    margin: 0;
    padding: 0;

    img {
      padding: 0 10px;
    }
  }
}

.form {
  padding: 0 15px 15px;

  form {
    width: 100%;
  }

  .formNoBottom {
    padding: 15px;
  }

  .inputs {
    border-radius: 5px;
    display: flex;
    background: white;

    .input {
      border: none;
      border-radius: 5px;
      flex: 1;
      font-size: 0.875em;
      margin: 5px 0;
      outline: none;
      padding: 10px 20px;
      color: $text;
    }

    .inputsText {
      display: flex;
      flex: 1;
      flex-direction: column;

      .inputCalculation {
        align-items: center;
        display: flex;
        padding-left: 10px;
        position: relative;

        img {
          height: 32px;
          width: 25px;
        }
      }

      .divider {
        background: $border-m;
        height: 1px;
        margin-left: 10px;
      }
    }

    .swap {
      align-self: center;
      background: url(/assets/images/swap.svg) no-repeat center;
      cursor: pointer;
      display: flex;
      height: 25px;
      margin: 10px;
      width: 25px;
    }
  }

  .represents {
    display: flex;
    margin: 5px 0 5px 5px;
    padding: initial !important;

    .radio {
      margin-right: 15px;
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      input {
        margin-right: 8px;
        margin-bottom: 5px;

        &:after {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          top: -2px;
          left: -3px;
          position: relative;
          background-color: #d1d3d1;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 2px solid white;
        }

        &:checked {
          &:after {
            background-color: $primary;
          }
        }
      }
    }
  }

  .highlight {
    background: $hover;
  }

  .prefs {
    margin: 15px 0px -5px;
    display: flex;
    align-self: flex-start;
    flex-direction: column;

    @include font-small;

    .buttons {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
    }

    .collapse {
      background: white;
      padding: 8px 10px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      display: flex;
      min-width: 115px;
      position: relative;

      &.date {
        width: 250px;
        margin-right: 15px;
      }

      span {
        padding: 0 0 0 5px;
        color: $primary;
        font-weight: 600;
      }

      .menu-item-arrow {
        position: absolute;
        right: 10px;
        top: 6px;
        transition: all ease-in 150ms;

        &.active {
          transform: rotate(90deg);
        }
      }
    }
  }

  .route-calculation-modes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    margin: 5px 2px 10px 2px;

    label {
      align-items: flex-start;
    }

    .route-calculation-mode {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
      background: #e6e6e6;
      border-radius: 5px;
      color: #a5a6a7;
      font-weight: 400;
      cursor: pointer;

      @include font-small;

      &.active {
        background: white;
        box-shadow: 0 0 5px $shadow-m;
      }

      img {
        height: 30px;
        padding: 5px 0;
      }
    }
  }

  .pickers {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .error,
  .warning {
    margin: 10px 0 0;
    padding: 10px 0;

    @include font-small;
  }
}

.loading {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 10px 10px;

  &.printing {
    padding: 0 15px 20px;
  }

  img {
    padding-bottom: 10px;
  }
}

.button {
  background: $primary;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  margin-top: 10px;
  padding: 15px;
  text-align: center;
  display: block;
}

.autocomplete {
  background: #ffffff;
  box-shadow: 2px 4px 10px $shadow-xs;
  margin: 48px 0 0;
  padding: 0;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
  position: fixed;
  width: 370px;
  z-index: 999;
  font-size: 0.875em;

  .autocompleteHistory {
    padding-bottom: 10px;
  }

  .autocompleteHeader {
    color: #909194;
    padding: 15px 10px;
    border-top: 1px solid $border-xs;
  }

  &.large {
    width: 410px;
  }

  &.small {
    width: 290px;
  }

  &.offsetRoute {
    left: 35px;
    width: 365px;
  }

  .autocomplete-line {
    padding: 5px 10px !important;
  }

  .item {
    align-items: center;
    display: flex;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;

    & img {
      padding: 0 10px 0 0;
      margin: 0 16px;
    }
  }

  .itemHighlight {
    background: $hover;
    cursor: pointer;
  }
}

.journeys {
  overflow: auto;
  padding: 0 15px;

  .soft {
    @include font-small;

    .soft-items {
      display: flex;
      padding-top: 0;
      margin-bottom: 15px;

      .softItem {
        cursor: pointer;
        display: flex;
        align-items: center;
        background: white;
        flex: 1;
        border-radius: 4px;
        max-width: 33%;
        margin: 0;
        box-shadow: 0 0 5px $shadow-xxs;

        &:nth-child(1) {
          margin-right: 5px;
        }

        &:nth-child(n + 2) {
          margin-left: 5px;
        }

        &:hover {
          background: white;
          box-shadow: 0 0 15px $shadow-xs;
        }

        .root-icon {
          min-width: 20px;
          justify-content: center;
          border: 2px solid $border-xs;
          border-radius: 5px;
          padding: 10px 5px;
          margin: 5px;
        }

        &:not(:last-child) {
          margin-right: 5px !important;
        }

        .softInfos {
          padding: 10px;
          border: none;

          .time {
            color: $primary;
            font-size: 1.1em;
          }
        }
      }
    }
  }

  .transport {
    margin-bottom: 15px;

    @include font-small;

    .transport-title {
      font-weight: 500;
      font-size: 1.1em;

      // Override PMR style for route calculation
      .route-calculation-pmr {
        display: inline-flex;
        align-items: center;
      }
    }

    .noSolutionTransport {
      padding: 10px 0 0;
    }

    .journey {
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0 0 15px $shadow-xs;
      color: #333333;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin: 15px 0;

      .journey-head {
        border-bottom: 1px solid $border-xs;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #888;

        .time {
          display: flex;
          align-items: center;
          text-align: initial;

          span {
            display: flex;
            align-items: center;

            span {
              padding-left: 5px;
              color: $primary;
              text-align: center;
            }

            &:not(:first-child):not(:last-child) {
              padding: 0 10px;
            }
          }
        }

        .leaf {
          height: 15px;
        }

        .walk,
        .co2 {
          display: flex;
          align-items: center;
          font-size: 0.9em;
          white-space: nowrap;
        }

        div {
          padding: 10px;

          &:not(:last-child) {
            border-right: 1px solid $border-xs;
          }

          &:first-child {
            flex: 1;
            text-align: center;
          }
        }
      }

      .journey-warning {
        border-bottom: 1px solid $border-xs;
        padding: 10px;
        color: #888;
        display: flex;
        align-items: center;

        .icon {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          z-index: 9999;
          background: url("/assets/images/disruptions/delays.svg") center center no-repeat;
        }
      }

      .journey-content {
        align-items: center;
        display: flex;
        position: relative;

        .journey-time {
          border-right: 1px solid $border-xs;
          padding: 10px;
          min-width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 70px;

          span {
            font-size: 1.6em;
            font-weight: 500;
          }
        }

        .journey-sections {
          align-items: center;
          display: flex;
          flex: 1;
          overflow: auto;
          padding: 20px 50px 20px 15px;
          white-space: nowrap;

          .line {
            border: none;

            &:hover {
              background: none;
            }
          }

          .delimiter {
            color: #cccccc;
            font-weight: 600;
          }

          .section {
            padding: 0 2px;
            display: flex;
            align-items: center;
          }

          .journey-sections-offset {
            min-height: 1px;
            padding: 0 20px;
          }
        }

        .journey-sections-arrow {
          background: linear-gradient(to left, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
          border-bottom-right-radius: 10px;
          padding: 24px 10px 24px 25px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          z-index: 9999;
        }
      }

      .journey-disruption {
        border-top: 1px solid $border-xs;
        background-color: rgba($error, 0.2);
        padding: 10px;
        border-radius: 0 0 5px 5px;

        .journey-disruption-avoid {
          margin-top: 10px;
          font-weight: 600;
          text-decoration: underline;
          text-align: right;
        }
      }

      &:not(.active) {
        background: white;
        border: none;
        box-shadow: 2px 2px 8px $shadow-xxs;

        .journey-sections-arrow {
          background: linear-gradient(to left, white 50%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }

    &.leave-earlier-or-later {
      display: flex;
      justify-content: space-between;

      .time {
        height: 18px;
      }

      .leave {
        cursor: pointer;
        display: flex;
        background-color: white;
        color: #58585a;
        align-items: center;
        padding: 5px 18px 5px 10px;
        border-radius: 4px;
      }
    }
  }

  .route-calculation-legal-notice {
    margin: 0 0 15px;
    padding: 15px;
    display: flex;
    align-items: center;

    @include font-x-small;

    a {
      display: flex;

      img {
        height: 15px;
        margin: 0 5px;
      }
    }
  }
}

.section, .section-part-type {
  .mode {
    display: flex;
    padding-left: 8px;
    margin-right: -3px;

    img {
      width: 25px;
    }
  }
}

.roadmap {
  overflow-y: auto;
  padding: 20px;

  @include font-small;

  .roadmap-summary {
    display: flex;
    align-items: center;
    margin: -20px -20px 20px;
    border-bottom: 1px solid $border-xs;
    color: #888;

    @include font-small;

    .duration {
      flex: 1;
      padding: 10px;

      span {
        color: $text;
        font-weight: 500;
      }
    }

    div.walk, div.co2 {
      display: flex;
      align-items: center;
      padding: 10px;
      border-left: 1px solid $border-xs;
      height: auto;

      .leaf {
        height: 15px;
      }
    }

    @include mobile {
      margin: -20px -10px 20px;
    }
  }

  .section-part-top,
  .section-part-bottom,
  .section-part-content {
    display: grid;
    cursor: pointer;
    grid-template-columns: 60px 40px 1fr;

    .section-part-time {
      font-weight: bold;
      text-align: center;
    }

    .section-part-border {
      position: relative;

      .section-part-bull {
        background: #ffffff;
        border: 3px solid #333333;
        border-radius: 50%;
        height: 10px;
        left: 12px;
        position: absolute;
        top: 0;
        width: 10px;
      }
    }

    .section-part-type {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-bottom: 20px;

      .root-icon {
        margin-left: 100px;
        background: white;
      }
    }

    .section-part-details {
      padding: 10px 0 20px;

      .little {
        font-size: 0.9em;

        .availability {
          img {
            width: 15px;
            padding-left: 5px;
          }
        }
      }

      strong {
        font-weight: 500;
      }

      .details {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 0.9em;
        font-weight: 500;
        margin-bottom: 5px;
        color: $primary;

        .details-collapse {
          color: $primary;
          font-weight: 500;
          padding-right: 10px;
        }

        img {
          height: 20px;
          padding: 0 5px;
          width: 15px;
        }
      }


      .section-part-details-stops {
        font-weight: 400;
        padding: 2px 0;
        position: relative;
        font-size: 0.9em;
        color: $text;
        margin-bottom: 5px;

        .section-part-details-stops-bull {
          background: #ffffff;
          border: 2px solid #576369;
          border-radius: 50%;
          height: 6px;
          left: -25px;
          position: absolute;
          top: 5px;
          width: 6px;
        }
      }

      .section-part-details-path {
        font-size: 0.85em;

        .section-part-details-walk-direction {
          img {
            height: 20px;
            vertical-align: -5px;
          }
        }
      }
    }
  }
}

.tab-list {
  border-bottom: 1px solid $border-xs;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @include font-small;

  .tab {
    cursor: pointer;
    flex: 1;
    margin-bottom: -2px;
    outline: none;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    position: relative;

    span {
      font-size: 0.8em;
      opacity: 0.6;
    }

    &.active {
      &:after {
        position: absolute;
        content: "";
        height: 5px;
        width: 36px;
        background-color: $primary;
        left: 50%;
        margin-left: -18px;
        bottom: -1px;
        border-radius: 8px / 5px;
        z-index: 1;
      }
    }
  }
}

.tab-panel {
  display: none;

  .empty {
    padding: 20px;
    @include font-small;
  }

  &.active {
    display: block;
  }
}

.group {
  cursor: pointer;
  padding: 15px 20px 5px;

  &:not(:last-child) {
    border-bottom: 1px solid $border-xs;
  }

  .group-offset-bottom {
    margin-bottom: 15px;
  }

  .category {
    padding: 10px 0;
    display: flex;
    align-items: center;

    @include font-small;

    img {
      padding-right: 10px;
    }
  }

  // Yeah yeah, nested class ... (look Services TCL div in around for ex.)
  .group {
    padding: 0;
  }

  .place {
    cursor: pointer;
    padding: 5px;
    display: flex;

    @include font-small;

    .bss,
    .parcs {
      display: flex;
      margin-top: 5px;
    }

    .parcs {
      padding: 5px 0;

      .parcs-title {
        font-weight: 500;
      }

      .seats {
        padding: 0 10px 0 0;
      }
    }

    .place-content {
      flex-direction: column;
      margin-left: 5px;

      .selected {
        font-weight: 500;
      }

      .parking-seats-informations {
        flex-direction: column;
      }

      .realtime-seats {
        background: #e0e0e0;
        border-radius: 5px;
        padding: 3px;
        align-self: flex-start;
        margin-bottom: 5px;

        img {
          width: 15px;
          padding-left: 3px;
        }
      }

      .place-infos {
        flex-direction: column;
        margin: 0;
        padding: 10px 0 10px 10px;
        font-weight: 300;

        .bss {
          margin-left: 0;
          justify-content: flex-start;
        }

        .station-schedules {
          display: flex;
          flex-direction: column;
          margin: 0;
          position: relative;

          div {
            flex: initial;
            margin-left: initial;
            justify-content: initial;
          }

          .station-schedules-header {
            border-bottom: 1px solid $border-m;
            padding: 5px 0;
            font-weight: 400;
          }

          .station-schedules-content {
            display: flex;
            flex-direction: column;
            padding: 5px 0;
            font-weight: 500;
            min-height: 20px;

            &.not-empty {
              min-height: 100px;
            }
          }

          .station-schedules-header,
          .station-schedules-entry {
            div:nth-child(1) {
              flex: 2;
            }

            div:nth-child(2) {
              flex: 3;
              display: flex;
              flex-direction: column;
            }

            .station-schedules-entry-mode {
              font-size: 0.9em;
              color: #aaa;
              font-weight: 400;
            }

            del {
              font-size: 0.9em;
              color: #999;
            }
          }

          .station-pagination {
            position: absolute;
            top: 0;
            right: 0;

            .station-pagination-previous,
            .station-pagination-next {
              padding: 3px 6px;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-radius: 5px;
              margin: 0 2px;

              img {
                width: 15px;
                height: 15px;
                transform: rotate(-90deg);
              }
            }

            .station-pagination-previous {
              margin-right: 5px;

              img {
                transform: rotate(90deg);
              }
            }
          }
        }

        .address,
        .mail,
        .phone,
        .info {
          margin-top: 5px;
          flex-direction: column;
        }

        .wheelchair {
          margin-top: 5px;
          display: flex;
        }

        .address-title,
        .mail-title,
        .phone-title,
        .wheelchair-title {
          font-weight: 500;
        }

        .loading {
          align-items: flex-start;
          padding: 5px;

          img {
            padding-bottom: 0;
          }
        }

        .opening-hours {
          flex-direction: column;

          .opening-hours-title {
            font-weight: 500;
          }

          .opening-hours-list {
            flex-direction: column;

            span {
              display: block;
            }
          }
        }
      }
    }

    .tool-favorite {
      margin-right: -10px;
      margin-top: -5px;
    }
  }
}

.group-name {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .mode {
    width: 100%;
  }

  .group-mode {
    font-weight: 500;

    @include small-mobile {
      @include font-small;
    }
  }

  .group-mode-logo {
    max-width: 45px;
    padding: 0 18px 0 10px;
  }

  .group-header {
    align-items: center;
    display: flex;
    flex: 1;

    img {
      height: 40px;
      padding-right: 10px;
      width: 30px;
      object-fit: initial;
    }
  }

  .arrow-group {
    display: flex;
    margin-right: -5px;

    img {
      height: 25px;
      transition: all ease-in 150ms;
      width: 25px;

      &.closed {
        transform: rotate(-90deg);
      }
    }
  }
}

.bikes,
.seats {
  display: flex;
  align-items: center;

  img {
    width: 25px;
    padding-left: 5px;
  }
}

.seats {
  padding-left: 15px;

  img {
    width: 12px;
  }
}

.time-picker-popup {
  z-index: 99999;

  .rc-time-picker-panel-inner {
    box-shadow: $shadow-xs 2px 2px 10px;
  }

  .rc-time-picker-panel-input {
    font-size: 1.1em;
  }
}

.time-picker-group {
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;

  img {
    height: 25px;
    padding-right: 5px;
  }

  .time-picker {
    input {
      width: 110px;
      outline: none;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 1em;

      &:focus {
        font-size: 16px;
      }
    }
  }
}

.walk,
.bss,
.bike,
.leaf {
  height: 18px;
}

.is-pmr {
  background: url(/assets/images/pmr.svg) no-repeat center;
  margin-left: 5px;
  height: 15px;
  width: 15px;

  &.section {
    display: inline-block;
    vertical-align: -2px;
  }
} 

.is-no-pmr {
  background: url(/assets/images/no-pmr.svg) no-repeat center;
  margin-left: 5px;
  height: 15px;
  width: 15px;
}

.is-tad {
  background: url(/assets/images/tad.svg) no-repeat center;
  margin-left: 5px;
  height: 15px;
  width: 15px;
}

.active-line {
  .line-header {
    border-bottom: 1px solid $border-m;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    padding: 10px;
    position: relative;

    .line:hover {
      background: initial;
    }

    .timetableOptions {
      min-width: 100px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
      background: white;
      box-shadow: -2px 3px 10px $shadow-xs;

      .timetableOptionsItem {
        text-align: center;
        padding: 10px;
        cursor: pointer;

        @include font-small;

        &:hover {
          background: $hover;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $border-xs;
        }
      }
    }

    .direction {
      flex: 1;
      margin-left: 10px;

      @include font-small;

      &.rer {
        font-size: 1.1em;
      }

      .tadLink {
        display: block;
        text-decoration: none;
        font-size: 1.2em;
        color: $primary;
        font-weight: 500;
        margin-top: 10px;
      }
    }

    .tools {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .line-tad-header {
    padding: 10px 20px 0px 20px;
    @include font-small;
  }

  .line-tad-informations {
    position: relative;
    padding: 10px 8px;
    border-bottom: 1px solid $border-m;
    @include font-small;

    img {
      padding: 10px 20px 10px 20px;
      max-width: 15px;
    }

    .line-tad-phone,
    .line-tad-website {
      display: flex;
      flex: 1 1;
      align-items: center;
    }
  }

  .stops {
    overflow: auto;
    padding: 10px;

    &.tad-no-thermo {
      padding-left: 25px;
    }

    @include font-small;

    .stop {
      cursor: pointer;
      display: flex;

      // Borne TCL
      &.selected {
        background-color: #{$primary}15;
        border-radius: 5px;
        //font-size: 1.2em;

        .seeTimetable {
          //font-size: 0.7em !important;
        }
      }

      .border {
        border-left: 4px solid;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-left: 20px;
        margin-top: 9px;

        @include mobile {
          margin-left: 18px !important;
        }
      }

      &:not(:first-child) .border {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
      }

      .first-border {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-top: 9px !important;
      }

      &:last-child .border {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 17px;
      }

      .point {
        position: relative;
        background: #ffffff;
        border-radius: 50%;
        height: 6px;
        margin: 8px 28px 0 -7px;
        width: 6px;

        .pulsate {
          z-index: 0;
          width: 10px;
          height: 10px;
        }

        .crossed:before, .crossed:after {
          position: absolute;
          left: 2px;
          content: ' ';
          height: 15px;
          top: -4px;
          width: 2px;
          background-color: #333;
        }

        .crossed:before {
          transform: rotate(45deg);
        }

        .crossed:after {
          transform: rotate(-45deg);
        }
      }

      .stop-terminus {
        height: 12px;
        margin: 5px 25px 0 -10px;
        width: 12px;

        .crossed:before, .crossed:after {
          left: 5px;
          top: -2px;
        }
      }

      .stop-name {
        flex: 1;
        padding: 5px 0 8px;

        &.tad-no-thermo {
          margin-left: -15px;

          &.selected {
            margin-left: -20px;
          }
        }

        .stop-and-tools {
          display: flex;
          align-items: center;

          span {
            color: $secondary;
            background-color: $primary;
            padding: 5px;
            border-radius: 5px;
            margin-left: -5px;
            margin-top: -2px;
          }
        }

        .connections {
          display: flex;

          .connection {
            border-radius: 2px;
            font-size: 0.7em;
            font-weight: 500;
            margin: 0 3px 10px 0;
            padding: 2px 4px;
          }
        }

        &.selected {
          margin-left: -10px;

          &.hasFavorite {
            padding: 0 0 8px;
            margin-top: -2px;
          }

          .connections {
            padding-left: 10px;
          }

          .selectedStop {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding-left: 10px;

            .stopActions {
              display: flex;

              .tool-favorite {
                padding-right: 10px;
              }

              .no-tool-favorite {
                height: 22px;
              }
            }
          }

          .selectedContent {
            display: flex;
            cursor: default;
            justify-content: space-between;
            align-items: center;

            .schedules {
              padding: 0 10px 0 20px;

              .nextSchedules {
                font-size: 0.9em;
                padding: 0 0 5px;
              }

              .schedule {
                align-items: center;
                display: flex;
                padding: 5px 0;
                font-size: 0.9em;

                img {
                  height: 12px;
                  margin: 0 5px;
                }
              }
            }

            .seeTimetable {
              align-items: center;
              border-radius: 5px;
              cursor: pointer;
              display: flex;
              padding: 10px;
              margin-right: 10px;

              border: 2px solid $border-xs;
              border-radius: 5px;

              @include font-small;

              & img {
                height: 25px;
                padding-right: 10px;
              }

              &:hover {
                background: $hover;
              }
            }
          }
        }
      }
    }
  }

  .timetable-stop {
    border-bottom: 1px solid $border-m;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include font-small;

    .print {
      cursor: pointer;
      background: url(/assets/images/pdf.svg) no-repeat center;
      width: 20px;
      height: 20px;
      margin-right: -5px;
    }

    @include mobile {
      padding: 10px 15px !important;

      .print {
        margin-right: 0 !important;
      }
    }
  }

  .odt {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .timetable {
    padding: 0 20px 15px;

    @include font-small;

    .timetableHead {
      cursor: pointer;
      display: flex;
      padding-bottom: 15px;
    }

    .timetableSlide {
      width: 100%;

      .timetableBodyHours {
        height: 24px;
        padding-top: 0px;
      }

      .timetableBody {
        display: flex;
        flex: 1;

        .timetableColumn {
          align-items: center;
          background: #ffffff;
          display: flex;
          flex: 1;
          flex-direction: column;

          &:nth-child(even) {
            background: #f4f4f4;
          }

          .timetableHour {
            color: $primary;
            font-weight: 600;
            font-weight: 600;
            padding: 5px 0;
          }

          .timetableMinutes {
            padding: 2px 0;

            .timetableMinutesLetter {
              color: red;
              font-size: 0.9em;
              font-weight: 300;
            }
          }
        }
      }
    }
  }

  .tad {
    background: url(/assets/images/tad.svg) no-repeat center;
    width: 60px;
    height: 20px;
    background-size: cover;
    border-radius: 2px;
    border: 1px solid $border-m;
  }

  .otherDirections {
    padding: 10px 10px 0;

    .otherdirectionsContent {
      padding: 2px 0;

      .otherdirectionsContentLetter {
        color: red;
        font-weight: 300;
        font-size: 0.9em;
      }
    }
  }

  @include small-mobile {
    .timetable-stop {
      padding: 15px 20px;
    }
  }
}

.lines {
  display: grid;
  text-align: center;
  grid-template-columns: repeat($grid-size, 1fr);
  grid-gap: 10px;

  &.large {
    grid-template-columns: repeat($grid-size, 1fr);
  }

  &.small {
    grid-template-columns: repeat($grid-size-mobile, 1fr);

    &.line-with-direction {
      text-align: left;

      .line {
        min-width: 40px !important;
        width: 40px !important;
      }
    }
  }

  &.line-with-direction {
    grid-template-columns: repeat(1, 1fr);
    .attribute-line {
      display: flex;
      align-items: center;

      .line {
        padding: 5px 5px;
        min-width: 50px;
        width: 50px;
        text-align: center;

        &.code {
          @include font-small;
        }

        &.mode {
          @include font-xx-small;
        }
      }

      .name {
        padding-left: 10px;
        text-align: left;
      }
    }
  }
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 7px;
  border: if($line-with-border, 2px solid $border-xs, none);
  border-radius: 5px;

  &:hover {
    background: $hover;
  }

  .roadmap & {
    &:hover {
      background: none;
    }
  }

  &.with-direction {
    padding: 5px 5px;
    border: 2px solid rgba(0, 0, 0, 0.08);
    width: 50px;
    font-size: 0.53em;
    text-align: center;

    &.code {
      @include font-small;
    }

    &.mode {
      @include font-xx-small;
    }
  }

  .pertIcon,
  .blockIcon {
    width: 14px;
    height: 14px;
    margin-left: -2px;
    z-index: 9999;
    background: url("/assets/images/disruptions/delays.svg") center center no-repeat;
  }

  .blockIcon {
    background: url("/assets/images/disruptions/blocking.svg") center center no-repeat;
  }

  img {
    height: $line-image-height;
    max-width: 65px;
  }

  @include mobile {
    padding: 7px !important;

    img {
      height: calc(#{$line-image-height} - 5px);
      max-width: 50px;
    }
  }
}

.line-label {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 60px;
}

.line-code {
  border-radius: 2px;
  cursor: pointer;
  font-weight: 500;
  min-width: 35px;
  display: flex;
  justify-content: center;
  padding: 3px 5px;
  white-space: nowrap;
}

.autocomplete-line {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;

  .line {
    min-width: 40px;

    &.line-with-direction {
      padding: 5px 5px;
      width: 50px;
      text-align: center;

      &.code {
        @include font-small;
      }

      &.mode {
        @include font-xx-small;
      }
    }

    img {
      height: calc(#{$line-image-height} - 5px);
      max-width: 40px;
    }
  }

  .autocompleteDirection {
    font-weight: 600;
  }
}

.long-line-code {
  letter-spacing: -1.5px;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.error,
.warning,
.blocking,
.delays,
.informations {
  color: #ffffff;
  margin: 10px 20px;
  padding: 0 10px;
  border-left: 3px solid;

  .disruptionSeverity {
    display: flex;
    align-items: center;
    font-weight: bold;

    .icon {
      width: 15px;
      padding: 0;
      margin: 0 5px 0 0;
    }
  }

  .disruptionSeverity::first-letter {
    text-transform: capitalize;
  }

  .disruptionTitle {
    font-weight: bold;
    margin-top: 5px;
  }

  .disruptionContent {
    color: #7a7a7a !important;
    margin: 5px 0;

    &.empty {
      font-style: italic;
    }

    p {
      margin: 0 0 5px !important;
    }
  }

  .disruption-dates {
    font-weight: bold;

    .disruption-dates-more {
      color: #7a7a7a;
      padding-top: 5px;
      font-weight: normal;
      cursor: pointer;
    }

    .collapsed {
      padding-top: 5px;
    }
  }
}

.error,
.warning {
  color: $error;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-left: none;
  padding: 0;

  img {
    background-size: contain !important;
    width: 15px;
    height: 15px;
    margin: 0 10px;
  }
}

.blocking,
.delays,
.informations {
  margin: 0 0 15px;
  color: $text;

  .icon {
    background-size: contain !important;
    width: 15px;
    height: 15px;
    margin: 0 15px 0 5px;
  }

  .disruption {
    flex: 1;
  }
}

.warning {
  color: $warning;
}

.blocking {
  border-left-color: $error;

  .disruptionSeverity {
    color: $error;

    .icon {
      background: url("/assets/images/disruptions/blocking.svg") center center no-repeat;
    }

    &.white {
      .icon {
        background: url("/assets/images/disruptions/blocking-white.svg") center center no-repeat;
      }
    }
  }
}

.delays {
  border-left-color: $warning;

  .disruptionSeverity {
    color: $warning;

    .icon {
      background: url("/assets/images/disruptions/delays.svg") center center no-repeat;
    }

    &.white {
      .icon {
        background: url("/assets/images/disruptions/delays-white.svg") center center no-repeat;
      }
    }
  }
}

.informations {
  border-left-color: $info;

  .disruptionSeverity {
    color: $info;

    .icon {
      background: url("/assets/images/disruptions/informations.svg") center center no-repeat;
    }
  }
}

.tool-swap,
.tool-timetable,
.tool-route-calculation,
.tool-favorite {
  cursor: pointer;
  height: 18px;
  margin: 3px;
  width: 25px;

  &.toolSmall {
    height: 15px;
    margin: 0;
  }
}

.tool-favorite {
  height: 25px;
}

.tool-swap {
  background: url(/assets/images/swap.svg) no-repeat center;
}

.tool-timetable {
  background: url(/assets/images/pdf.svg) no-repeat center;
  display: block;
  height: 23px;

  &.jd {
    background: url(/assets/images/pdf-jd.svg) no-repeat center;
  }
}

.tool-route-calculation {
  background: url(/assets/images/menu/route-calculation.svg) no-repeat center;
  background-color: none;
  padding: 5px;
  border: 1px solid $border-m;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: $hover;
  }
}

.tool-favorite {
  background: url(/assets/images/favorite.svg) no-repeat center;

  &.active {
    background: url(/assets/images/favorite-active.svg) no-repeat center;
  }
}

.root-icon {
  border-radius: 20px;
  color: if(luminance($primary) > 0.5, #333333, #ffffff);
  display: flex;
  align-items: center;
  height: 18px;
  padding: 8px 5px;

  .car {
    height: 16px !important;
  }

  .icon {
    align-self: center;
    background: transparent;
    height: 100%;
  }
}
