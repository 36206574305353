.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 1);
  transition: all 250ms ease-in-out;
  opacity: 0;
  overflow: hidden;

  &.active {
    opacity: 1;
  }

  .head {
    display: flex;
    align-items: center;
    padding: 0 15px;
    background: white;

    .close {
      padding-right: 25px;
      margin-left: -10px;
      background: url(/assets/images/back.svg) center center no-repeat;
      width: 20px;
      height: 20px;
    }
  }

  .input {
    border: none;
    border-radius: 5px;
    flex: 1;
    font-size: 16px !important;
    margin: 5px 0;
    outline: none;
    padding: 15px;
  }

  .autocomplete {
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 0;
    max-height: calc(100vh - 60px);
    overflow: scroll;

    .item {
      padding: 10px;
      font-size: 0.95em;
      font-weight: 600;

      img {
        padding: 0 10px 0 0;
        margin: 0 16px;
      }
    }

    .line {
      padding: 7px 10px !important;
    }
  }
}