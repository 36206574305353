@import 'functions';
@import '../../public/assets/variables.scss';

:export {
  primarycolor: $primary;
  secondaryjourney: $secondary-journey;
  headerHeight: $header-height;
  headerHeightMobile: $header-height-mobile;
  defaultBoardTop: $board-mobile-default-top;
  boardMarginTop: $board-mobile-margin-top;
  boardTranslateY: $board-mobile-translateY-offset;
}

html,
body {
  position: fixed;

  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;

  color: $text;

  font-family: $font, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;

  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
}

#app {
  display: flex;
  overflow: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;

  width: 100vw;
  height: 100vh;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  @include mobile {
    flex-direction: column-reverse;
  }
}

.app-header {
  display: flex;
  align-items: center;

  background-image: url("/assets/images/header/background.svg");
  background-repeat: no-repeat;
  background-color: $primary;
  background-position: right;
  background-size: auto 120px;

  height: 120px;
  color: white;

  img {
    max-height: 120px;
    padding-left: 35px;
  }

  span {
    padding-left: 20px;
    font-size: 2.5em;
    font-weight: 400;
  }

  @media screen and (max-width: #{950px}) {
    span {
      display: none;
    }
  }
  
  @include mobile {
    position: fixed;
    background-size: auto 60px;
    height: 60px;
    width: 100vw;

    span {
      font-size: 20px;
    }

    img {
      max-height: 60px;
      padding-left: 35px;
    }
  }
}

.container {
  display: flex;

  @include mobile {
    height: 100vh;
  }
}

@import 'board';
@import 'map';
@import 'modal';
@import 'bikes';
@import 'disruptions';
@import "../../public/assets/override.scss";

@if $override-styles {
  @include override;
}

// Global plugins overrides
.slider-control-bottomcenter {
  top: -23px;

  li button {
    padding: 0 2px !important;

    outline: none;

    font-size: 16px !important;
  }
}

// react-collapse v5
.ReactCollapse--collapse {
  transition: height 500ms;
}

// Should be moved into react-picker-date-and-time instead
.click-outside {
  display: inline-block;
}

.notification {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;

  margin: 0;
  padding: 20px;

  color: white !important;
  border-radius: 0;

  &.error {
    display: flex;
    justify-content: center;

    background: #58585a;
  }

  &.ios {
    top: 143px;
    right: 10px;
    left: 10px;
  }

  &::before {
    width: 15px;
    height: 15px;
    margin-right: 15px;

    content: '';

    background: url(/assets/images/cross-error.svg) no-repeat center;
    background-size: 15px;
  }
}

.tippy-tooltip.latitude-theme {
  background-color: $primary;
  color: white;
  @include font-x-small;
}

.tippy-tooltip.latitude-theme[data-placement^='top'] .tippy-arrow {
  border-top-color: $primary;
}

.tippy-tooltip.latitude-theme[data-placement^='bottom'] .tippy-arrow {
  border-bottom-color: $primary;
}

.tippy-tooltip.latitude-theme[data-placement^='left'] .tippy-arrow {
  border-left-color: $primary;
}

.tippy-tooltip.latitude-theme[data-placement^='right'] .tippy-arrow {
  border-right-color: $primary;
}